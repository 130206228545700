@font-face {
  font-family: 'rubik';
  src: url('@assets/font/rubik.ttf');
}
@font-face {
  font-family: 'Rubik-Light';
  src: url('@assets/font/Rubik-Light.ttf');
}
@font-face {
  font-family: 'Rubik-Medium';
  src: url('@assets/font/Rubik-Medium.ttf');
}
