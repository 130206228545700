@import url("./iconfont.css");

html,
body {
  font-family: "Rubik-Light  !important", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif !important;

  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  background-color: #fff !important;
}

html {
  overflow: unset !important;
}

body {
  /* min-height: 100vh; */
  /* scroll-behavior: smooth; */
  text-rendering: optimizeSpeed;
  overflow-x: hidden;
  overflow-y: auto;
}
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Remove default padding */

ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a class attribute */

ul[class],
ol[class],
li[class] {
  list-style: none !important;
}

ul,
ol,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

/* A elements that don't have a class get default styles */

a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */

img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */

article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */

input,
button,
textarea,
select {
  font: inherit;
  outline: none;
}

pre {
  font-family: "rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Remove all animations and transitions for people that prefer not to see them */

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

* {
  box-sizing: border-box;
  flex-shrink: 0;
}

* {
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

.scroll-wrapper-0 {
  overflow-x: overlay;
  overflow-y: overlay;
}

.scroll-wrapper-0::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
  /* 整个滚条背景 */
}
.scroll-wrapper-0::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #39394d;
  /* 滚条内嵌颜色 */
}
.scroll-wrapper-0::-webkit-scrollbar-button {
  background-color: transparent;
  width: 0;
  height: 0;
  /* 两边端按钮颜色 */
}

.scroll-wrapper-8 {
  overflow-x: overlay !important;
  overflow-y: overlay !important;
}

.scroll-wrapper-8::-webkit-scrollbar {
  width: 8px !important;
  height: 0 !important;
  background: transparent !important;
  /* 整个滚条背景 */
}
.scroll-wrapper-8::-webkit-scrollbar-thumb {
  border-radius: 8px !important;
  background: #39394d96 !important;
  /* 滚条内嵌颜色 */
}
.scroll-wrapper-8::-webkit-scrollbar-button {
  background-color: transparent !important;
  width: 8px !important;
  height: 0 !important;
  /* 两边端按钮颜色 */
}

body {
  overflow-x: overlay !important;
  overflow-y: overlay !important;
}

::-webkit-scrollbar {
  width: 8px !important;
  height: 0 !important;
  background: transparent !important;
  /* 整个滚条背景 */
}
::-webkit-scrollbar-thumb {
  border-radius: 8px !important;
  background: #39394d96 !important;
  /* 滚条内嵌颜色 */
}
::-webkit-scrollbar-button {
  background-color: transparent !important;
  width: 8px !important;
  height: 0 !important;
  /* 两边端按钮颜色 */
}

.font-variant-numeric {
  font-variant-numeric: tabular-nums lining-nums;
}

.MuiInputBase-input {
  flex: 1 !important;
}
