/* @tailwind base;
@tailwind components;
@tailwind utilities; */

@import "tailwindcss/base";
@import url("./assets/css/basic.css");

@import "tailwindcss/components";

@import "tailwindcss/utilities";
